import React from "react";
import PropTypes from "prop-types";

const OtpContactSupport = ({ supportEmail, supportPhone, supportContact }) => {
  const hasContacts = supportEmail || supportPhone;

  const renderContacts = () => {
    if (!hasContacts) return null;

    const rawPhone = supportPhone ? supportPhone.replace(/[^\d]/g, "") : "";
    const phone = (
      <a className="otp__info__support-contact" href={`tel:${rawPhone}`}>
        {supportPhone}
      </a>
    );
    const email = (
      <a className="otp__info__support-contact" href={`mailto:${supportEmail}`}>
        {supportEmail}
      </a>
    );

    if (supportEmail && supportPhone) {
      return (
        <>
          {phone} or {email}
        </>
      );
    }

    if (supportPhone) {
      return <>{phone}</>;
    }

    return <>{email}</>;
  };

  return (
    <p>
      {supportContact}
      {hasContacts && <> at {renderContacts()}</>}
    </p>
  );
};

OtpContactSupport.propTypes = {
  supportEmail: PropTypes.string,
  supportPhone: PropTypes.string,
  supportContact: PropTypes.string.isRequired,
};

export default OtpContactSupport;
